import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import { StyledLinkContainer } from 'components/Utilities/StyledLink/styles'

export const Content = styled.div`
  position: relative;
  margin: 92px auto 120px;
  padding: 130px 0 150px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 65px 0;
    margin: 46px auto 60px;
  }
`

export const TitleStyled = styled(Title)``

export const SecondaryTitleStyled = styled(Title)`
  margin-bottom: 80px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: 40px;
  }

  :last-of-type {
    margin-top: 40px;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      margin-top: 20px;
    }
  }
`

export const TertiaryTitleStyled = styled(Title)`
  font-weight: 500;
  margin-right: 140px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-right: 100px;
  }

  @media (max-width: 600px) {
    margin-right: 50px;
  }

  @media (max-width: 500px) {
    margin-right: 20px;
  }

  :first-of-type {
    width: 350px;

    @media (max-width: 500px) {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  :last-of-type {
    @media (max-width: 500px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`

export const TextStyled = styled(Text)``

export const Header = styled.div`
  position: relative;
  width: 100%;
  padding-top: 0px;
  margin-top: 140px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 100px;
  }

  .AnimatedLine {
    bottom: initial;
    top: -15px;
    right: -185px;
    width: 100vw;

    @media (min-width: ${props => props.theme.breakpoints.xxl}) {
      right: 100px;
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      right: -20px;
      width: 100%;
    }
  }
`

export const JobContainer = styled.div`
  background-color: ${props => props.theme.colors.lightblue};
  margin: 0 auto;
  width: 100%;
`

export const JobPost = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  :not(:last-of-type) {
    margin-bottom: 35px;
  }
`

export const JobLinkContainer = styled(StyledLinkContainer)`
  white-space: nowrap;
  align-items: center;
`

export const JobLink = styled.a`
  font-size: 18px !important;
  font-weight: bold !important;
`
