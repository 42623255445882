import React from 'react'
import { ImageContainer, FullScreenImage } from './styles'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

const ImageFullScreen = props => {
  const { fullScreen = false, aspectRatio, image, style } = props
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('600'))

  return image?.localFile?.childImageSharp?.fluid ? (
    <ImageContainer style={style} fullScreen={fullScreen}>
      <FullScreenImage
        fluid={
          aspectRatio && isTablet
            ? { ...image?.localFile?.childImageSharp?.fluid, aspectRatio }
            : !isTablet &&
              image?.thumbnails?.mobile?.localFile?.childImageSharp?.fluid
            ? image?.thumbnails?.mobile?.localFile?.childImageSharp?.fluid
            : image?.localFile?.childImageSharp?.fluid
        }
        alt={image?.alt}
      />
    </ImageContainer>
  ) : (
    <></>
  )
}

export default ImageFullScreen
