import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import LinkFormatter from 'components/LinkFormatter'

export const ExpertiseSliderSectionContainer = styled.div`
  width: 100%;
  max-width: 2000px;
  /* margin: 60px auto; */
  background: white !important;
  margin-top: -36px;
  padding-top: 30px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    &.ExpertiseSliderSectionContainer--0 {
      margin-top: -100px;
      padding-bottom: 250px;
    }

    margin: auto;
    display: flex;
    flex-direction: ${props => (props.reverseOrder ? 'row-reverse' : 'row')};
    align-items: center;
    /* margin-top: 125px; */
    padding-bottom: 250px;
    padding-right: 50px;
    padding-left: 35px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    padding-left: 100px;
    padding-right: 186px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    padding-left: 186px;
    padding-right: 186px;
  }
`

export const SliderImage = styled(Img)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  min-width: 320px;
  min-height: 320px;
  left: 0px;
  top: 20px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-width: 400px;
    min-height: 400px;
    top: 0;
    left: 20px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    min-width: 527px;
    min-height: 527px;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 320px;
  background-color: ${props => props.backgroundColor};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 400px;
    height: 400px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    width: 527px;
    height: 527px;
  }
`

export const StyledTitle = styled(Title)`
  font-weight: 500;
  margin-left: ${props => (!props.reverseOrder ? '-70px' : '40px')};
  margin-bottom: 20px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.fonts.xl_small};
    margin-bottom: 20px;
    margin-left: 0px;
    margin-top: 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 80px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    margin-top: 30px;
  }
`

export const StyledSecondaryTitle = styled(Title)`
  font-weight: 500;
  line-height: 31px;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: ${props => (!props.reverseOrder ? '40px' : '0')};
    line-height: 44px;
  }
`
export const StyledBody = styled(Text)`
  font-weight: 200;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: ${props => (!props.reverseOrder ? '40px' : '0')};
    margin-bottom: 20px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    margin-bottom: 50px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.fonts.xxxs_large};
    margin-bottom: 35px;
  }
`

export const ContentContainer = styled.div`
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 40px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 527px;
    height: 527px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const StyledCTA = styled(LinkFormatter)`
  text-decoration: none;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: ${props => (!props.reverseOrder ? '40px' : '0')};
  }
`
