import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import styled from '@emotion/styled'
import Image from 'gatsby-image'

export const Content = styled.div`
  padding-top: 25px;
  position: relative;
  margin: 0 auto 120px;
  justify-content: center;
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin: 40px auto 60px;
    padding-top: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

export const Column = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */

  h4{
     font-size: 20px !important;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    margin: 0 auto 20px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 400px;
  }

  :not(:last-of-type) {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-right: 100px;
    }
  }
`

export const ImageStyled = styled(Image)`
  width: 100px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 110px;
  }
`

export const TitleStyled = styled(Title)``

export const ColumnTitleStyled = styled(Title)`
  margin-bottom: 25px;
`

export const TextStyled = styled(Text)``

export const Header = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 60px;

  .AnimatedLine {
    bottom: initial;
    top: -20px;
    right: -185px;
    width: 100vw;

    @media (min-width: ${props => props.theme.breakpoints.xxl}) {
      right: 100px;
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      right: -20px;
      width: 100%;
    }
  }
`
