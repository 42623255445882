export const getHeaderContentNew = query => {
    const data =
      query?.length > 0 &&
      query?.map((el, index) => {
        return {
          primaryTitle:
            el?.content_component_link?.document?.data?.title_primary?.text,
          secondaryTitle:
            el?.content_component_link?.document?.data?.title_secondary?.text,
          index: index,
          body: el?.content_component_link?.document?.data?.body?.text,
          line_decoration: el?.content_component_link?.document?.data?.line_decoration,
          component_position:
            el?.content_component_link?.document?.data?.component_position,
          font_weight_title_primary:
            el?.content_component_link?.document?.data?.font_weight_title_primary,
          font_weight_title_secondary:
            el?.content_component_link?.document?.data?.font_weight_title_secondary,
          heading_is_scrollable:
            el?.content_component_link?.document?.data?.heading_is_scrollable,
        }
      })
    return data
  }
  