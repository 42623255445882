import styled from '@emotion/styled'
import Text from 'components/Utilities/Text'
import Title from 'components/Utilities/Title'

export const ProjectExcerptsTitle = styled(Title)`
  width: 100%;
  margin-bottom: 50px;
`

export const ProjectExcerptsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 20px;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 0 auto 100px;
    padding: 0 100px;
  }

  .StyledLink__ProjectExcerpt {
    font-size: ${props => props.theme.fonts.xxxs_extralarge}!important;
    text-decoration: underline;
    font-weight: bold;
  }

  .AnimatedLine__ProjectExcerpt {
    bottom: unset;
    width: calc(100% - 40px);
    right: 20px;
    max-width: 350px;
    transform: none;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      right: 10px;
    }
  }
`

export const StyledExcerpt = styled(Text)`
  margin-bottom: 20px;
  padding-top: 15px;
  font-weight: 500;
  line-height: 1.63;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 30px;
    padding-top: 15px;
  }
`

export const StyledDate = styled(Text)`
  font-weight: 500;
  margin-bottom: 15px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.fonts.xs};
    font-weight: bold;
  }
`
