import React from 'react'
import Hero from 'components/Hero'
import PlainContent from 'components/PlainContent'
import Columns from 'components/Columns'
import IconColumnGroup from 'components/IconColumnGroup'
import JobListing from 'components/JobListing'
import Images from 'components/Images'
import Wrapper from 'components/Wrapper'
import Content from 'components/Content'
import ImageFullScreen from 'components/ImageFullScreen'
import BlogSnippet from 'components/BlogSnippet'
import GroupOfTwo from 'components/GroupOfTwo'
import ProjectsGroupLayout from 'components/PagesLayout/ProjectsGroupLayout'
import ProjectExcerptsNew from 'components/ProjectExcerptsNew'
import NewBasic from 'components/NewBasic'
import PreFooterComponentNew from 'components/PreFooterNew'
import ProjectHighlightComponent from 'components/ProjectHighlight'
import ContentWithIcons from 'components/ContentWithIcons'

const Builder = props => {
  const {
    data: { prismicGlobalContent, prismicArticleGlobalContent },
  } = props

  const components = props?.data?.prismicPage?.data?.components

  const heroComponent = components?.find(
    ({ component: { document } }) =>
      document?.__typename === 'PrismicHeroComponent'
  )

  const heroProps = {
    ...heroComponent?.component?.document?.heroData,
    ...prismicGlobalContent?.data,
    style: { overflow: 'hidden' },
  }
  return (
    <>
      <Hero {...heroProps} />
      <Wrapper
        hasFullHeight={
          heroComponent?.component?.document?.heroData?.full_screen_height
        }
      >
        {components?.map(({ component: { document } }) => {
          switch (document?.__typename) {
            case 'PrismicFullwidthProjectHighlight':
              return (
                <ProjectHighlightComponent
                  key={document.id}
                  query={document?.data}
                />
              )
            case 'PrismicBodyContentSectionWithIcons':
              return (
                <ContentWithIcons
                  key={document.id}
                  data={{
                    primary: { content_section_with_icons: { document } },
                  }}
                />
              )
            case 'PrismicPlainContentComponent':
              return <PlainContent key={document.id} {...document} />
            case 'PrismicColumnsComponent':
              return <Columns key={document.id} {...document} />
            case 'PrismicJobListing':
              return <JobListing key={document.id} {...document} />
            case 'PrismicImagesComponent':
              return <Images key={document.id} {...document} />
            case 'PrismicContentComponent':
              return <Content key={document.id} {...document} />
            case 'PrismicProjectsGroup':
              return <ProjectsGroupLayout key={document.id} {...document} />
            case 'PrismicGenericSection':
              return <NewBasic key={document.id} {...document} />
            case 'PrismicFeaturedArticles':
              return (
                <ProjectExcerptsNew
                  key={document.id}
                  {...document}
                  globalQuery={prismicGlobalContent}
                />
              )
            case 'PrismicPrefooter':
              return <PreFooterComponentNew key={document.id} {...document} />
            case 'PrismicIconColumnGroup':
              return <IconColumnGroup key={document.id} {...document} />
            case 'PrismicImage':
              return (
                <ImageFullScreen
                  key={document.id}
                  image={document?.data?.image}
                />
              )
            case 'PrismicBlogSnippet':
              return (
                <BlogSnippet
                  style={{ backgroundColor: 'transparent' }}
                  query={{
                    title: document?.data?.title,
                    estimated_read_time:
                      prismicArticleGlobalContent.data.estimated_read_time,
                    back_to: document?.data?.cta_text,
                  }}
                  articles={document?.data?.featured_articles}
                  key={document.id}
                />
              )
            case 'PrismicGroupof2':
              return <GroupOfTwo key={document.id} {...document} />
            default:
              return ''
          }
        })}
      </Wrapper>
    </>
  )
}

export default Builder
