import React from 'react'
import Container from 'components/Container'
import { Content, ImageStyled, ImageContainer } from './styles'

const Images = props => {
  const {
    data: { images },
  } = props

  return (
    <Container>
      <Content>
        {images?.map(({ image }, index) => {
          return image?.localFile?.childImageSharp?.fluid ? (
            <ImageContainer>
              <ImageStyled
                index={index}
                alt={image.alt}
                fluid={{
                  ...image.localFile.childImageSharp.fluid,
                  aspectRatio:
                    index === 0
                      ? 527 / 660
                      : index === 1
                      ? 638 / 407
                      : 638 / 407,
                }}
              />
            </ImageContainer>
          ) : (
            <></>
          )
        })}
      </Content>
    </Container>
  )
}

export default Images
