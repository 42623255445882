import styled from '@emotion/styled'
import Image from 'gatsby-image'
import StyledLink from 'components/Utilities/StyledLink'
import Text from 'components/Utilities/Text'

export const PrefooterWrapper = styled.div`
  background-color: ${props => props.theme.colors.primarygreen};
  width: 100%;
  min-height: 500px;
  margin: 0 auto;
`

export const PreFooterContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 512px;
  position: relative;

  margin-top: 0;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-height: 650px;
    flex-direction: row;
    max-width: 2000px;
    /* margin: 200px auto; */
    margin-bottom: 0px;
    margin-top: ${props => props.add_top_padding ? "500px" : ''};

  }
`

export const MainImage = styled(Image)`
  width: 100%;
  min-height: 250px;
  max-width: 1190px;

  @media (min-width: 1600px) {
    max-width: 1400px;
  }
`

export const ContentBanner = styled.div`
  background-color: ${props => props.theme.colors.lightblue};
  padding: 35px 20px 35px;
  position: absolute;
  max-width: 650px;
  min-height: 207px;
  top: 50%;
  right: 24%;
  transform: translate(33%, -50%);

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 90px 60px 100px;
    top: 0;
    right: 130px;
    transform: translateX(0%);
  }
`

export const BodyStyled = styled(Text)`
  font-weight: 200;
  margin-bottom: 40px;
`

export const CtaStyled = styled(StyledLink)`
  padding-top: 40px;
  font-weight: 200;
`
