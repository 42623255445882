import React from 'react'
import Container from 'components/Container'
import { GroupOfTwoContainer } from './styles'
import ContentWithIcons from 'components/ContentWithIcons'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

const GroupeOfTwo = props => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('960'))
  const {
    data: { components },
  } = props

  return (
    <GroupOfTwoContainer>
      <Container>
        {components?.map(({ component: { document } }) => {
          switch (document.__typename) {
            case 'PrismicBodyContentSectionWithIcons':
              return (
                <ContentWithIcons
                  style={{
                    margin: isDesktop ? 0 : '60px 0 0',
                    padding: 0,
                  }}
                  key={document.id}
                  data={{
                    primary: { content_section_with_icons: { document } },
                  }}
                />
              )
            default:
              return ''
          }
        })}
      </Container>
    </GroupOfTwoContainer>
  )
}

export default GroupeOfTwo
