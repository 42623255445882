import React from 'react'
import {
  ContentContainer,
  TitleStyled,
  TextStyled,
  TitlesContainer,
  TextContainer,
  Overline,
} from './styles'
import AnimatedLine from 'components/AnimatedLine'
import ImageFullScreen from 'components/ImageFullScreen'
import StyledLink from 'components/Utilities/StyledLink'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Fade } from 'react-reveal'

const Content = props => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('960'))
  const {
    data: {
      title,
      body,
      cta_link,
      cta_text,
      image,
      overline,
      page_type,
      title_bold,
    },
  } = props

  return (
    <>
      {image && (
        <ImageFullScreen
          style={{
            marginBottom: isDesktop ? 0 : '-40px',
            marginTop: isDesktop ? 80 : 60,
          }}
          image={image}
          fullScreen
          aspectRatio={1680 / 525}
        />
      )}
      <ContentContainer hasOverline={overline?.text}>
        <AnimatedLine className="ContentAnimatedLine" />
        <TitlesContainer>
          <Fade distance="30px" bottom>
            <>
              {overline?.text && (
                <Overline as="h4" type="h4">
                  {overline?.text}
                </Overline>
              )}
              <TitleStyled
                style={{ fontWeight: title_bold ? 700 : null }}
                as="h2"
                type="h3"
                dangerouslySetInnerHTML={{ __html: title?.html }}
              />
            </>
          </Fade>
        </TitlesContainer>
        <TextContainer>
          <Fade distance="30px" bottom>
            <>
              <TextStyled
                as="p"
                type="primary"
                dangerouslySetInnerHTML={{ __html: body?.html }}
              />
              {page_type && cta_link?.uid && page_type && (
                <StyledLink to={`/${cta_link?.uid}`} pageType={page_type}>
                  {cta_text?.text}
                </StyledLink>
              )}
            </>
          </Fade>
        </TextContainer>
      </ContentContainer>
    </>
  )
}

export default Content
