import Container from 'components/Container'
import React from 'react'
import {
  Column,
  Content,
  TextStyled,
  ImageStyled,
  TitleStyled,
  ColumnTitleStyled,
  Header,
} from './styles'
import AnimatedLine from 'components/AnimatedLine'

const Columns = props => {
  const {
    data: { columns, title },
  } = props

  return (
    <Container>
      <Content>
        <Header>
          <AnimatedLine className="AnimatedLine" />
          <TitleStyled
            as="h2"
            type="h3"
            dangerouslySetInnerHTML={{ __html: title.html }}
          />
        </Header>
        {columns?.map(column => {
          const { image, column_title, column_body } = column

          return (
            <Column>
              <div>
                {image?.localFile?.childImageSharp?.fluid ? (
                  <ImageStyled fluid={image.localFile.childImageSharp.fluid} />
                ) : (
                  <></>
                )}
                {column_title?.html ? (
                  <ColumnTitleStyled
                    as="h3"
                    type="h4"
                    dangerouslySetInnerHTML={{ __html: column_title.html }}
                  />
                ) : (
                  <></>
                )}
              </div>
              {column_body?.text ? (
                <TextStyled style={{ fontWeight: 300 }} type="primary">
                  {column_body?.text}
                </TextStyled>
              ) : (
                <></>
              )}
            </Column>
          )
        })}
      </Content>
    </Container>
  )
}

Columns.propTypes = {}

export default Columns
