import React from 'react'
import Container from 'components/Container'
import AnimatedLine from 'components/AnimatedLine'
import {
  Content,
  TitleStyled,
  SecondaryTitleStyled,
  TertiaryTitleStyled,
  Header,
  JobContainer,
  JobPost,
  JobLink,
  JobLinkContainer,
} from './styles'

const JobListing = props => {
  const {
    query: { body: components, title },
  } = props

  return (
    <JobContainer>
      <Container>
        <Content>
          <TitleStyled
            as="h2"
            type="h3"
            dangerouslySetInnerHTML={{ __html: title.html }}
          />
          {components?.map(component => {
            return (
              <div key={component.id}>
                {component.primary?.title?.html &&
                component?.items?.length > 0 ? (
                  <>
                    <Header>
                      <AnimatedLine className="AnimatedLine" />
                      <SecondaryTitleStyled
                        as="h3"
                        type="h4"
                        dangerouslySetInnerHTML={{
                          __html: component.primary?.title?.html,
                        }}
                      />
                    </Header>

                    {component.items?.map(item => {
                      return (
                        <JobPost>
                          <TertiaryTitleStyled as="h4" type="h4">
                            {item.position?.text}
                          </TertiaryTitleStyled>
                          <TertiaryTitleStyled as="h4" type="h4">
                            {item.location?.text}
                          </TertiaryTitleStyled>
                          <JobLinkContainer>
                            <JobLink
                              className={'StyledLink'}
                              target={item.cta_link?.target}
                              href={item.cta_link?.url}
                            >
                              {item.cta_text?.text}
                            </JobLink>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              style={{ marginLeft: 10, marginTop: -4 }}
                            >
                              <path
                                d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
                                fill="#043022"
                              />
                            </svg>
                          </JobLinkContainer>
                        </JobPost>
                      )
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            )
          })}
        </Content>
      </Container>
    </JobContainer>
  )
}

export default JobListing
