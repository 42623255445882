import styled from '@emotion/styled'

export const GroupOfTwoContainer = styled.div`
  > div {
    display: flex;
    justify-content: space-between;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      flex-direction: column;
    }
  }

  .BodyContentContainer {
    margin: 0;
    width: calc(50% - 15px);

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      width: 100%;
    }
  }

  .PrimaryContainer {
    width: 100%;
  }

  .IconContainer {
    margin-bottom: 0;
  }
`
