import React from 'react'

import Container from 'components/Container'
import {
  Content,
  InnerContent,
  ImageStyled,
  TitleStyled,
  SecondaryTitleStyled,
  TextStyled,
} from './styles'

const PlainContent = props => {
  const {
    plainContentComponentData: { body, image, secondary_title, title },
  } = props


  return (
    <Container>
      <Content>
        <ImageStyled
          fluid={{
            ...image?.localFile?.childImageSharp.fluid,
            aspectRatio: 1 / 1,
          }}
          alt={image?.alt}
        />
        <InnerContent>
          <TitleStyled
            type="h3"
            as="h2"
            dangerouslySetInnerHTML={{ __html: title.html }}
          />
          {secondary_title?.text ? (
            <SecondaryTitleStyled as="h3" type="h4">
              {secondary_title.text}
            </SecondaryTitleStyled>
          ) : (
            <></>
          )}
          {body?.html ? (
            <TextStyled
              type="primary"
              dangerouslySetInnerHTML={{ __html: body.html }}
            />
          ) : (
            <></>
          )}
        </InnerContent>
      </Content>
    </Container>
  )
}

export default PlainContent
