import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import Image from 'gatsby-image'

export const Content = styled.div`
  padding-top: 100px;
  display: flex;
  padding-bottom: 92px;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 152px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 50px 0 46px 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const ImageStyled = styled(Image)`
  max-width: 527px;
  width: 50%;
  height: auto;

  @media (max-width: 600px) {
    max-width: initial;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
`

export const InnerContent = styled.div`
  padding-top: 25px;
  padding-left: 20px;
  width: 50%;
  max-width: 420px;

  @media (max-width: 600px) {
    max-width: initial;
    width: 100%;
    padding: 20px 0 0;
  }
`

export const TitleStyled = styled(Title)`
  line-height: 1.1;
  margin-left: -105px;

  @media (max-width: 600px) {
    margin: 0;
  }
`

export const SecondaryTitleStyled = styled(Title)`
  display: inline-block;
  margin-top: 30px;
  font-weight: bold;
`

export const TextStyled = styled(Text)`
  margin-top: 30px;
  font-weight: 300;
`
