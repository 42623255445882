import styled from '@emotion/styled'
import Image from 'gatsby-image'

export const Content = styled.div`
  padding-top: 25px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 92px auto 10px;
  justify-content: flex-end;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin: 46px auto 60px;
  }
`

export const ImageStyled = styled(Image)`
  width: ${props =>
    props.index === 0
      ? 'calc(100% - 20px)'
      : props.index === 1
      ? 'calc(100% - 60px)'
      : 'calc(100% - 60px)'};
  margin: ${props =>
    props.index === 0
      ? '0 auto 0 0'
      : props.index === 1
      ? '40px 0 0 auto'
      : '0'};

  @media (max-width: 600px) {
    margin-top: 0;
  }
`

export const ImageContainer = styled.div`
  width: 50%;
  margin-bottom: 90px;

  @media (max-width: 600px) {
    margin-bottom: 40px;
    width: 100%;
  }
`
