import styled from '@emotion/styled'
import Image from 'gatsby-image'

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 2000px;
  margin: 40px auto;
  padding: ${props => (props.fullScreen ? '0' : '0 20px')};

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin: -40px auto 120px;
    padding: ${props => (props.fullScreen ? '0' : '0 50px')};
  }

  @media (min-width: 1270px) {
    padding: ${props => (props.fullScreen ? '0' : '0 186px')};
  }
`

export const FullScreenImage = styled(Image)``
