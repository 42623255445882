import React from 'react'
import PropTypes from 'prop-types'
import {
  PrefooterWrapper,
  PreFooterContainerStyled,
  MainImage,
  BodyStyled,
  ContentBanner,
  CtaStyled,
} from './styles'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

const PreFooterComponentNew = (props) => {

  const { data: { body, cta, image, add_top_padding }, className } = props
  const theme = useTheme()  
  const isDesktop = useMediaQuery(theme.breakpoints.up('450'))

  return (
    <PrefooterWrapper>
      <PreFooterContainerStyled className={className} add_top_padding={add_top_padding}>
        {image?.localFile?.childImageSharp?.fluid ? (
          <MainImage
            fluid={{
              ...image?.localFile?.childImageSharp?.fluid,
              aspectRatio: isDesktop ? 1190 / 653 : 320 / 270,
            }}
            alt={image?.alt}
          />
        ) : (
          <></>
        )}
        <ContentBanner>
          {body?.text ? (
            <BodyStyled type="super-big" as="p">
              {body?.text}
            </BodyStyled>
          ) : (
            <></>
          )}
          {cta?.[0]?.cta_link?.uid && cta?.[0]?.cta_label?.text ? (
            <CtaStyled
              to={cta?.[0]?.cta_link?.uid}
              pageType={cta?.[0]?.page_type}
            >
              {cta?.[0]?.cta_label?.text}
            </CtaStyled>
          ) : (
            <></>
          )}
        </ContentBanner>
      </PreFooterContainerStyled>
    </PrefooterWrapper>
  )
}

PreFooterComponentNew.propTypes = {
  query: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default PreFooterComponentNew
