import React, { useEffect, useRef, useState } from 'react'
import AnimatedLine from 'components/AnimatedLine'
import {
  StickyHeaderContainer,
  StickyHeaderMainContainer,
  StickyHeaderContent,
  FixedTitleStyled,
  MainBody,
} from './../StickyHeader/styles'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { PrimaryTitle } from './styles'
import { TranslateRevealStyled } from '../AnimationTexts/TranslateReveal/styles'

const HeaderComponent = ({ currentSection, query, styleContext }) => {
  const titlePrimary = query?.[currentSection]?.primaryTitle
  const fontWeightTitlePrimary =
    query?.[currentSection]?.font_weight_title_primary
  const fontWeightTitleSecondary =
    query?.[currentSection]?.font_weight_title_secondary
  const titleSecondary = query?.[currentSection]?.secondaryTitle
  const line_decoration = query?.[currentSection]?.line_decoration
  const component_position = query?.[currentSection]?.component_position
  const bodyText = query?.[currentSection]?.body
  const headingIsScrollable = query?.[currentSection]?.heading_is_scrollable
  const headerRef = useRef(null)
  const primaryTitleRef = useRef(null)
  const secondaryTitleRef = useRef(null)
  const bodyRef = useRef(null)
  const [primaryTitleHeight, setPrimaryTitleHeight] = useState(null)
  const [secondaryTitleHeight, setSecondaryTitleHeight] = useState(null)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('769'))

  useEffect(() => {
    if (primaryTitleRef?.current) {
      setPrimaryTitleHeight(primaryTitleRef?.current?.clientHeight)
    }
  }, [primaryTitleRef?.current, currentSection])

  useEffect(() => {
    if (secondaryTitleRef?.current) {
      setSecondaryTitleHeight(secondaryTitleRef?.current?.clientHeight)
    }
  }, [secondaryTitleRef?.current])

  useEffect(() => {
    Splitting({ force: true })
    primaryTitleRef?.current?.classList?.add('animate')
    secondaryTitleRef?.current?.classList?.add('animate')
    bodyRef?.current?.classList.add('animate')
  }, [currentSection])

  return headingIsScrollable ? (
    <StickyHeaderMainContainer
      ref={headerRef}
      noPrimaryTitle={!titlePrimary}
      componentPosition={component_position}
      style={{
        position: 'sticky',
        top: '50px',
        paddingBottom: '300px',
        paddingTop: '125px',
        height: '400px',
        pointerEvents: 'none',
      }}
    >
      <StickyHeaderContainer
        noPrimaryTitle={!titlePrimary}
        heightOffsetSecondary={secondaryTitleHeight}
      >
        <StickyHeaderContent>
          {titlePrimary ? (
            <TranslateRevealStyled>
              <PrimaryTitle
                type="h2"
                as="h2"
                fontWeight={fontWeightTitlePrimary}
                style={{ top: isDesktop ? '-25px' : 'unset' }}
              >
                <span
                  ref={primaryTitleRef}
                  className="content__paragraph"
                  data-splitting="lines"
                >
                  {titlePrimary}
                </span>
              </PrimaryTitle>
            </TranslateRevealStyled>
          ) : (
            ''
          )}
          {titleSecondary ? (
            <TranslateRevealStyled>
              <FixedTitleStyled
                fontWeight={fontWeightTitleSecondary}
                type="h3"
                as="h3"
              >
                <span
                  ref={secondaryTitleRef}
                  className="content__paragraph"
                  data-splitting="lines"
                >
                  {titleSecondary}
                </span>
              </FixedTitleStyled>
            </TranslateRevealStyled>
          ) : (
            ''
          )}
          {bodyText ? (
            <TranslateRevealStyled>
              <MainBody>
                <span
                  ref={bodyRef}
                  className="content__paragraph"
                  data-splitting="lines"
                >
                  {bodyText}
                </span>
              </MainBody>
            </TranslateRevealStyled>
          ) : (
            ''
          )}
        </StickyHeaderContent>
      </StickyHeaderContainer>
      {line_decoration ? (
        <AnimatedLine
          className="StickyHeader__AnimatedLine"
          color={
            styleContext?.styleActivated === 'style 2' ? 'white' : '#043022'
          }
          shortLine
          heightOffset={primaryTitleHeight + 100}
          heightOffsetSecondary={secondaryTitleHeight}
          noPrimaryTitle={!titlePrimary}
        />
      ) : (
        <></>
      )}
    </StickyHeaderMainContainer>
  ) : (
    <></>
  )
}

export default HeaderComponent
