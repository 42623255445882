import React, { useContext } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import HeaderComponent from 'components/HeaderComponent'
import GenericSectionNew from 'components/GenericSectionNew'
import { BasicContainer } from './styles'
import { getHeaderContentNew } from 'utils/functions/getHeaderContentNew'
import StyleContext from 'context/StyleProvider'

const NewBasic = props => {
  const  items  = props?.genericSection?.body[0]?.items

  const styleContext = useContext(StyleContext)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('769'))
  const headerArrayQuery = getHeaderContentNew(items)

  return (
    <BasicContainer>
      {headerArrayQuery.length > 0 && isDesktop ? (
        <HeaderComponent
          query={headerArrayQuery}
          currentSection={styleContext?.currentSectionVisible}
          styleContext={styleContext}
        />
      ) : (
        <></>
      )}
      {items?.length > 0 ? (
        items.map((item, index) => {
          return (
            <GenericSectionNew
              query={item}
              lastElement={index === items.length - 1 || items.length === 1}
              isFirstElement={index === 0}
              index={index}
              key={`GenericSection -- ${index}`}
              styleContext={styleContext}
              withFixedHeading={
                isDesktop &&
                item?.content_component_link?.document?.data?.heading_is_scrollable
              }
            />
          )
        })
      ) : (
        <></>
      )}
    </BasicContainer>
  )
}

export default NewBasic
