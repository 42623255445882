import Container from 'components/Container'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Column,
  Content,
  TextStyled,
  ImageStyled,
} from './styles'

const IconColumnGroup = props => {
  const {
    data: { icon_group },
  } = props

  return (
    <Container>
      <Content>
        {icon_group?.map(column => {
          const { icon, icon_title } = column

          return (
            <Column>
              <div>
                {icon?.localFile?.childImageSharp?.fluid ? (
                  <ImageStyled fluid={icon.localFile.childImageSharp.fluid} />
                ) : (
                  <></>
                )}
              </div>
              {icon_title?.text ? (
                <TextStyled style={{ fontWeight: 300 }} type="primary">
                  {icon_title?.text}
                </TextStyled>
              ) : (
                <></>
              )}
            </Column>
          )
        })}
      </Content>
    </Container>
  )
}

IconColumnGroup.propTypes = {
  props: PropTypes.object.required
}

export default IconColumnGroup
