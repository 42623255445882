import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'

export const Overline = styled(Title)`
  transform: translateY(-40px);
  margin-top: -30px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: -60px;
    transform: translateY(-65px);
  }
`

export const ContentContainer = styled.div`
  position: relative;
  max-width: 2000px;
  margin: 0 auto;
  padding: 220px 0 160px;
  background: ${props => props.theme.colors.white};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: ${props =>
      props.hasOverline ? '130px 20px 0' : '80px 20px 40px'};
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }

  .ContentAnimatedLine {
    bottom: initial;
    top: 80px;
    width: 100vw;

    @media (min-width: 600px) {
      right: -130px;
      width: 100%;
      top: 25px;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      top: 80px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xl}) {
      right: -110px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxl}) {
      right: 190px;
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      top: ${props => (props.hasOverline ? '52px' : '25px')};
    }

    @media (max-width: 600px) {
      width: 90%;
      top: ${props => (props.hasOverline ? '52px' : '20px')};
    }
  }
`

export const TitleStyled = styled(Title)`
  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    margin-right: 20px;
  }
`

export const TitlesContainer = styled.div`
  width: 100%;
  max-width: calc(100% - 20px);
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-right: 30px;
    max-width: 600px;
    width: 50%;
    padding-right: 50px;
    position: relative;
    margin-left: 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin-left: 100px;
    /* margin-right: auto; */
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    margin-left: 186px;
  }
`

export const TextStyled = styled(Text)`
  line-height: 1.45;
  font-weight: 500;
`

export const TextContainer = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin-right: 150px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-bottom: 20px;
    max-width: 674px;
    width: 50%;
    margin-right: 50px;
    margin-bottom: initial;
  }

  .StyledLink {
    margin-top: 10px;
    display: inline-block;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-top: 40px;
    }
  }
`
