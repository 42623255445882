import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Utilities/Button'
import Fade from 'react-reveal/Fade'

import {
  StyledCTA,
  ExpertiseSliderSectionContainer,
  ImageContainer,
  SliderImage,
  StyledTitle,
  StyledSecondaryTitle,
  StyledBody,
  ContentContainer,
} from './styles'

const ProjectsGroupLayout = props => {

  const items = props?.projectBodyData?.body[0]?.items

  return (
    <>
      {items.length > 0 &&
        items.map((item, index) => {
            item = item?.projects?.document?.data
            let pageType;
            if (item?.cta_link?.uid === 'expertise') {
              pageType = 'page'
            } else {
              pageType = 'project'
            }
          return (
            <ExpertiseSliderSectionContainer
              backgroundColor={item?.background_color}
              key={index}
              className={`ExpertiseSliderSectionContainer--${index}`}
              reverseOrder={index % 2 !== 0}
            >
              <Fade distance="30px" bottom>
                {item?.project_image?.localFile?.childImageSharp?.fluid ? (
                  <ImageContainer backgroundColor={item?.background_color}>
                    <SliderImage
                      fluid={
                        item?.project_image?.localFile?.childImageSharp?.fluid
                      }
                      alt={item?.project_image?.alt}
                    ></SliderImage>
                  </ImageContainer>
                ) : (
                  <></>
                )}

                <ContentContainer>
                  {item?.project_title_primary?.text ? (
                    <StyledTitle
                      as="h3"
                      type="h3"
                      reverseOrder={index % 2 !== 0}
                    >
                      {item?.project_title_primary?.text}
                    </StyledTitle>
                  ) : (
                    <></>
                  )}
                  {item?.project_title_secondary?.text ? (
                    <StyledSecondaryTitle as="h4" type="h4">
                      {item?.project_title_secondary?.text}
                    </StyledSecondaryTitle>
                  ) : (
                    <></>
                  )}
                  {item?.project_body_text?.html ? (
                    <StyledBody
                      as="p"
                      type="primary"
                      dangerouslySetInnerHTML={{
                        __html: item?.project_body_text?.html,
                      }}
                    >
                      {}
                    </StyledBody>
                  ) : (
                    <></>
                  )}

                  {item?.cta_link?.uid && item?.cta_text ? (
                    <StyledCTA
                      pageHandle={item?.cta_link?.uid}
                      pageType={pageType}
                    >
                      <Button>{item?.cta_text}</Button>
                    </StyledCTA>
                  ) : (
                    <></>
                  )}
                </ContentContainer>
              </Fade>
            </ExpertiseSliderSectionContainer>
          )
        })}
    </>
  )
}

ProjectsGroupLayout.propTypes = {
  query: PropTypes.object.isRequired,
}

export default ProjectsGroupLayout
