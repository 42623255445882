import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import StyledLink from 'components/Utilities/StyledLink'
import AnimatedLine from 'components/AnimatedLine'
import {
  ProjectExcerptsContainer,
  StyledExcerpt,
  StyledDate,
  ProjectExcerptsTitle,
} from './styles'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  item: {
    paddingRight: '20px',
    paddingLeft: '20px',
    marginBottom: '30px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      maxWidth: '350px',
      '&.last': {
        marginRight: '0px',
      },
    },
  },
}))

const ProjectExcerptsNew = props => {
  const {
    featuredArticles: { body: body },
    globalQuery,
    featuredArticles: { project_excerpt_title },
  } = props


  const classes = useStyles()

  return (
    <ProjectExcerptsContainer>
      {project_excerpt_title?.text && (
        <ProjectExcerptsTitle type="h2" as="h2">
          {project_excerpt_title?.text}
        </ProjectExcerptsTitle>
      )}
      <Grid container justify="space-between" spacing={6}>
        {body[0].items?.map((item, index) => {
          const excerpt =
            item?.article?.document?.data.excerpt ||
            item?.article?.document?.data.article_excerpt
          const title =
            item?.article?.document?.data.project_title ||
            item?.article?.document?.data.title
          const dateCreated =
            item?.article?.document?.data?.date ||
            item?.article?.document?.first_publication_date

          return excerpt ? (
            <Grid
              key={index}
              item
              xs={12}
              md={4}
              className={
                index === body[0].items.length - 1
                  ? `${classes.item} last`
                  : classes.item
              }
            >
              <Fade cascade distance="30px" bottom>
                {dateCreated ? (
                  <StyledDate as="p" type="primary">
                    {dateCreated}
                  </StyledDate>
                ) : (
                  <></>
                )}
                <AnimatedLine className="AnimatedLine__ProjectExcerpt" />
                {title?.text && (
                  <StyledExcerpt
                    style={{ margin: 0, padding: '25px 0 0 0' }}
                    type="primary"
                    as="h3"
                  >
                    {title?.text}
                  </StyledExcerpt>
                )}
                {excerpt?.text && (
                  <StyledExcerpt as="p" type="secondary-small">
                    {excerpt?.text}
                  </StyledExcerpt>
                )}
                {item?.article?.document?.uid &&
                  globalQuery?.data?.read_more?.text && (
                    <StyledLink
                      className="StyledLink__ProjectExcerpt"
                      pageType={item?.article?.document?.data?.page_type}
                      to={item?.article?.document?.uid}
                    >
                      {globalQuery?.data?.read_more?.text}
                    </StyledLink>
                  )}
              </Fade>
            </Grid>
          ) : (
            <></>
          )
        })}
      </Grid>
    </ProjectExcerptsContainer>
  )
}

ProjectExcerptsNew.propTypes = {
  projectsQuery: PropTypes.array,
  locale: PropTypes.string.isRequired,
  globalQuery: PropTypes.object.isRequired,
}

export default ProjectExcerptsNew
